import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/css/theme.css";
import 'typeface-roboto';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store.ts';
import Amplify from 'aws-amplify'
import { AwsRum } from 'aws-rum-web';



try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_RUM_GUESTROLEARN,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITYPOOLID,
    endpoint: "https://dataplane.rum.us-east-2.amazonaws.com",
    telemetries: [
      "performance",
      "errors", 
      // Docs say we need to add this flag https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-modify-snippet.html
      [ "http", { addXRayTraceIdHeader: true } ] ],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATIONID;
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-east-2';

  const awsRum = new AwsRum( // eslint-disable-line
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    authenticationFlowType: process.env.REACT_APP_AUTH_FLOW
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
