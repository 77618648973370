import { createApi, fetchBaseQuery,  } from '@reduxjs/toolkit/query/react';
import { GetAccessToken } from '../shared/AuthControl';
import { UserInfoV2 } from '../shared/AuthTs';

export interface Asset {
    customerAssetId: string,
    description: string,
    id: number,
    image: string,
    locationAddress: string
    locationId: number,
    locationName: string,
    locationRoomDescription: string,
    locationRoomName: string,
    make: string,
    model: string,
    name: string,
    parentAssetName: string,
    placementInRoom: string,
    placementType: string,
    sensorId: string,
    serial: string,
    typeName: string
}

interface AssetByIdRequest {
    userInfo: UserInfoV2,
    id: number
}

export const AssetApiSlice = createApi({
    reducerPath: "assets",
    baseQuery: fetchBaseQuery({
        baseUrl:  `${process.env.REACT_APP_API_QUICKBASE_URL}api/asset/`,
        async prepareHeaders(headers){
            headers.set("Content-Type", "application/json");
            headers.set('x-api-key', process.env.REACT_APP_API_QUICKBASE_KEY);
            headers.set('authorization',  await GetAccessToken());
            
            return headers;
        }
    }),
    endpoints(builder){
        return {
            fetchAssets: builder.query<Asset[], UserInfoV2>({
                async queryFn(userInfo, queryApi, extraOptions, fetchWithBaseQuery) {

                    let results = [];

                    // let promises = userInfo.customerUser.userRoles.map(async (role) => {
                    //     let urlPath;
                    //     if(role.userLocation){
                    //         urlPath = `customer/${role.userCompany.companyReferenceId}/location/${role.userLocation.locationReferenceId}`;
                    //     }
                    //     else{
                    //         urlPath = `customer/${role.userCompany.companyReferenceId}`;
                    //     }

                    //     let response =  await fetchWithBaseQuery(urlPath) as { data: { data: Asset[] }};
                    //     results.push.apply(results, response.data.data);
                    // }); 

                    // await Promise.all(promises);
                    return { data: results };
                }
            }),
            fetchAssetById: builder.query<Asset, AssetByIdRequest>({
                async queryFn(args, queryApi, extraOptions, fetchWithBaseQuery) {
                    //let results = [];

                    // let promises = args.userInfo.customerUser.userRoles.map(async (role) => {
                    //     let urlPath;
                    //     if(role.userLocation){
                    //         urlPath = `customer/${role.userCompany.companyReferenceId}/location/${role.userLocation.locationReferenceId}`;
                    //     }
                    //     else{
                    //         urlPath = `customer/${role.userCompany.companyReferenceId}`;
                    //     }

                    //     let response =  await fetchWithBaseQuery(urlPath) as { data: { data: Asset[] }};
                    //     results.push.apply(results, response.data.data);
                    // }); 
                    // await Promise.all(promises);

                    // let search = results.filter((ele:Asset) => {
                    //     return ele.id = args.id;
                    // });

                    //return { data: search[0] };
                    return { data: null }
                }
            })
        }
    },
    refetchOnMountOrArgChange: Number(process.env.REACT_APP_DATA_TTL)
})

export const { useFetchAssetsQuery, useFetchAssetByIdQuery } = AssetApiSlice;