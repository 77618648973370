//This is the setup for the Redux Store. New reducers will need to be added here.

import {configureStore } from '@reduxjs/toolkit';
import { LimitedReportApiSlice } from './components/customer/LegacyWorkOrderApiSlice';
import { LocationApiSlice } from './components/customer/LocationApiSlice';
import { AssetApiSlice } from './components/customer/AssetApiSlice';

export const store = configureStore({
    reducer: { 
        [LimitedReportApiSlice.reducerPath]: LimitedReportApiSlice.reducer,
        [LocationApiSlice.reducerPath]: LocationApiSlice.reducer,
        [AssetApiSlice.reducerPath]: AssetApiSlice.reducer,
    },

    middleware: (getDefaultMiddleware)  => {
        return getDefaultMiddleware()
            .concat(AssetApiSlice.middleware)
            .concat(LocationApiSlice.middleware)
            .concat(LimitedReportApiSlice.middleware);       //For api entries
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;