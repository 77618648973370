import { CustomerLocation } from './../shared/CustomerService';
import { createApi, fetchBaseQuery,  } from '@reduxjs/toolkit/query/react';
import { GetAccessToken } from '../shared/AuthControl';
import { UserInfoV2 } from '../shared/AuthTs';

interface GetLocationById {
    id: number,
    userInfo: UserInfoV2
}

export const LocationApiSlice = createApi({
    reducerPath: 'customerLocations',
    baseQuery: fetchBaseQuery({
        baseUrl:  `${process.env.REACT_APP_API_CUSTOMER_URL}CustomerService/v1/api/Customers/`,
        async prepareHeaders(headers){
            headers.set('authorization',  await GetAccessToken());
            
            return headers;
        }
    }),
    endpoints(builder){
        return {
            fetchLocations: builder.query<CustomerLocation[], UserInfoV2>({
                async queryFn(userInfo, queryApi, extraOptions, fetchWithBaseQuery) {
                    
                    let result:CustomerLocation[] = [];

                    let promises = userInfo.customerUser.userRoles.map(async (userRole) => {
                        if(userRole.customerId)
                        {
                            let urlPath:string;
                            if(userRole.locationId){
                                urlPath = `${userRole.customerId}/locations/${userRole.locationId}`;
                            }
                            else{
                                urlPath = `${userRole.customerId}/locations`;
                            }

                            let response = await fetchWithBaseQuery(urlPath);
                            
                            if(response && response.data){
                                if(Array.isArray(response.data)){
                                    result.push.apply(result, response.data);
                                }
                                else {
                                    result.push.apply(result, [response.data]);
                                }
                            }
                        }
                    });

                    await Promise.all(promises);
                    return { data: result };
                }
            }),
            fetchLocationById: builder.query<CustomerLocation, GetLocationById>({
                async queryFn(args, queryApi, extraOptions, fetchWithBaseQuery) {
                    
                    let fullList:CustomerLocation[] = [];

                    let promises = args.userInfo.customerUser.userRoles.map(async (userRole) => {
                        if(userRole.customerId)
                        {
                            let urlPath:string;
                            if(userRole.locationId){
                                urlPath = `${userRole.customerId}/locations/${userRole.locationId}`;
                            }
                            else{
                                urlPath = `${userRole.customerId}/locations`;
                            }

                            let response =  await fetchWithBaseQuery(urlPath);
                            
                            if(response && response.data){
                                if(Array.isArray(response.data)){
                                fullList.push.apply(fullList, response.data);
                                }
                                else {
                                    fullList.push.apply(fullList, [response.data]);
                                }
                            }
                        }
                    });

                    await Promise.all(promises);

                    let search = fullList.filter((ele:CustomerLocation) => {
                        return ele.locationId === args.id
                    });

                    return ( search.length > 0 ? { data: search[0] } : null );
                }
            })
        }
    },
    refetchOnMountOrArgChange: Number(process.env.REACT_APP_DATA_TTL)
});

export const { useFetchLocationsQuery, useFetchLocationByIdQuery } = LocationApiSlice;