import { Auth } from 'aws-amplify';
import { GetUserV2 } from "./services";

  export function GetCurrentUserV2() {
    return Auth.currentAuthenticatedUser()
      .then(user => {
          const userInfo = localStorage.getItem(process.env.REACT_APP_USER_STORAGE);
          if(userInfo){
            return JSON.parse(userInfo);
          }
          else{
            const impersonation = localStorage.getItem("vizyon_impersonation");
            return GetUserV2(impersonation)
              .then(user => {
                localStorage.setItem(process.env.REACT_APP_USER_STORAGE, JSON.stringify(user)); 
                return JSON.parse(localStorage.getItem("process.env.REACT_APP_USER_STORAGE"));
              })
              .catch(ex => {
                if(impersonation) {
                  return GetUserV2('')
                    .then(user => {
                      localStorage.setItem(process.env.REACT_APP_USER_STORAGE, JSON.stringify(user)); 
                      return JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_STORAGE));
                    });
                }
            })
          }
      })
      .catch(ex => {
        localStorage.clear();
      });
  };

  export function GetCurrentUserEmail(){
    return Auth.currentAuthenticatedUser()
    .then(user => user.attributes.email);
  }

export function GetAccessToken(){
   return Auth.currentSession().then(res=>{
    let accessToken = res.getIdToken()
    return accessToken.getJwtToken()
  })
}
