import { createApi, fetchBaseQuery, FetchArgs } from '@reduxjs/toolkit/query/react';
import { GetAccessToken } from '../shared/AuthControl';
import { UserInfoV2 } from '../shared/AuthTs';
import { LegacyWorkOrder } from '../shared/WorkOrderService';
import Enumerable from 'linq';

interface WorkOrderByIdRequest {
    userInfo: UserInfoV2,
    workOrderId: number
}

export const LimitedReportApiSlice = createApi({
    reducerPath: "LegacyWorkOrders",
    baseQuery: fetchBaseQuery({
        baseUrl:  `${process.env.REACT_APP_API_WORKORDER_URL}WorkOrderService/v1/api/`,
        async prepareHeaders(headers){
            headers.set('authorization',  await GetAccessToken());
            headers.set('Access-Control-Allow-Origin', process.env.REACT_APP_CORS_ORIGINS)
            
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchWorkOrders: builder.query<LegacyWorkOrder[], UserInfoV2>({
                async queryFn(user, queryApi, extraOptions, fetchWithBaseQuery) {
                    let results:LegacyWorkOrder[] = [];

                    let userRoles = Enumerable.from(user.customerUser.userRoles);

                    var customerGroups = userRoles.groupBy(n => n.customerId).select(n => ({
                        customerId: n.key(),
                        // eslint-disable-next-line eqeqeq
                        locationIds: n.select(m => m.locationId).toArray().filter(m => m != null) // weird null entry left over from the select.
                    })).toArray();

                    customerGroups = customerGroups.filter((ele) => ele?.customerId)

                    let response =  await fetchWithBaseQuery({
                        url: 'customer/projects',
                        method: "POST",
                        body: {
                            bulkLegacyWorkOrderCustomerRequests: customerGroups
                        }
                    } as FetchArgs);
                    
                    if(response && response.data){
                        results.push.apply(results, response.data);
                    }

                    return { data: results };
                }
            }),
            fetchWorkOrderById: builder.query<LegacyWorkOrder, WorkOrderByIdRequest>({
                async queryFn(args, queryApi, extraOptions, fetchWithBaseQuery) {
                    let results:LegacyWorkOrder[] = [];

                    let userRoles = Enumerable.from(args.userInfo.customerUser.userRoles);

                    var customerGroups = userRoles.groupBy(n => n.customerId).select(n => ({
                        customerId: n.key(),
                        // eslint-disable-next-line eqeqeq
                        locationIds: n.select(m => m.locationId).toArray().filter(m => m != null) // weird null entry left over from the select.
                    })).toArray();

                    customerGroups = customerGroups.filter((ele) => ele?.customerId)

                    let response =  await fetchWithBaseQuery({
                        url: 'customer/projects',
                        method: "POST",
                        body: {
                            bulkLegacyWorkOrderCustomerRequests: customerGroups
                        }
                    } as FetchArgs);
                    
                    if(response && response.data){
                        results.push.apply(results, response.data);
                    }

                    let result = results.filter((record) => {
                        return record.id === args.workOrderId;
                    });

                    return  { data: result[0] };
                }
            })
        }
    },
    refetchOnMountOrArgChange: Number(process.env.REACT_APP_DATA_TTL)
});

export const { useFetchWorkOrdersQuery, useFetchWorkOrderByIdQuery } = LimitedReportApiSlice;
