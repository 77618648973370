import "./App.css";
import logo from "./assets/images/vizyon-logo-tagline-1.png";


function App() {
  return (
    <div className="main-content">
    <div style={{maxWidth:"650px", mixWidth:"350px", display:"block", backgroundColor: "grey"}}><img src={logo} alt="" style={{width:"100%"}} /></div>
    <h1>Thank you for using Vizyon, but we have moved!</h1>
    <div>You can now find us at <a href="https://customers.facilityhawk.com">https://customers.facilityhawk.com</a></div>
    <div>Please contact us at <a href = "mailto: helpdesk@facilityhawk.com">helpdesk@facilityhawk.com</a> if you have any questions or would like a demo of the new portal.</div>
    <div>If no action is taken, you will be directed to the new portal in 60 seconds.</div>
    </div>
  );
}

export default App;
